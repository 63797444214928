<template>
  <wp-items-view
      content-type="news-articles"
      content-type-singular="news-article"
      content-name="News articles"
      content-name-singular="News article"
  />
</template>

<script>
import WpItemsView from '@/views/wp-item/wp-items-view/WpItemsView.vue'

export default {
  components: {
    WpItemsView,
  },
}
</script>

<style scoped>

</style>
